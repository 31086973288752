import React, { useState } from "react";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const Disclaimer = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation("common");
  const { pathname } = window.location;
  let listHeader = true;

  if (pathname === "/login") {
    listHeader = false;
  }

  const handleClose = () => {
    setIsVisible(false);
  };

  if (listHeader) {
    return (
      isVisible && (
        <div className={style.banner}>
          <div className={style.bannercenter}>
            <span className={style.bannertext}>
              {t("common:disclaimer:disclaimerMessage")}
              <a
                href="https://confluence.walmart.com/display/CBDT/Sensitive+Personal+Information"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                click here
              </a>
              <br />
              <span className={style.bannertext}>
                {t("common:disclaimer:disclaimerSubMessage")}
              </span>
            </span>
          </div>
          <div className={style.bannerclose}>
            <CloseIcon
              size="small"
              className={style.closeIcon}
              data-testid="closeBtn"
              onClick={handleClose}
            />
          </div>
        </div>
      )
    );
  } else return null;
};

export default Disclaimer;
