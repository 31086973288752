/*eslint-disable*/
import _ from "lodash";
import { CACHE_SURVEY_RESPONSE_KEY } from "../constants";
import { POLICY_LIST, ROLE } from "../../components/constants";
import cookie from "react-cookies";
import { returnTokenOrForceReAuthentication, redirectUserToSSO } from "../sso";
import {
  formatSurveyServerToApp,
  formatSurveyAppToServer,
  formatSurveyQuestionsServerToApp,
  formatTranslations,
  formatModifiedQuestions,
} from "./utils";
import config from "../../config/config";
import "whatwg-fetch";
import uuid from "react-uuid";
import { trackTrace } from "../commons";
import i18n from "../../locales";
import i18next from "i18next";
import getCampaignParams from "../../utils/getCampaignParams";

var localStorage = require("localStorage");
export const contentBaseUrl = config.API_SERVER_URL;
export const endpoint = config.API_ENDPOINT;
export const helpLink = config.HELP_LINK;
export const blob_endpoint = config.API_ENDPOINT_UFMS; //"http://localhost:8086";
const THANK_YOU_DEFAULT = "Thank You!";
const THEME_DEFAULT = 1;
const SEARCH_CIP = endpoint + "/search";
const SURVEY_ENDPOINT = endpoint + "/survey";
const CLONE_SURVEY_ENDPOINT_SLASH_SUFFIXED = endpoint + "/clonesurvey/";
const SURVEY_ENDPOINT_SLASH_SUFFIXED = SURVEY_ENDPOINT + "/";
const LOGOUT_ENDPOINT = endpoint + "/logout";
const TEMPLATE_ENDPOINT = endpoint + "/templates/surveys/";
const NOTIFICATION = "/notifications";
const AUTO_SAVE = "/autosave";
const SAVE = "/save";

//Request processed successfully
const isResponseSuccess = (response) => {
  return response.type === "success";
};

//Invalid request - May be data validation failure
const isResponseFailed = (response) => {
  return response.type === "error";
};

//200 Response
const is200Response = (response) => {
  return response.status === 200;
};

//Internal serveer error
const is500Response = (response) => {
  return response.status === 500;
};

//Service unavailable error
const is520Response = (response) => {
  return response.status === 520;
};

//Internal serveer error
const is422Response = (response) => {
  return response.status === 422;
};

//Not authorized to access
const is403Response = (response) => {
  return response.status === 403;
};

//Unauthenticated access - token expired, missing or invalid
const is401Response = (response) => {
  return response.status === 401;
};

//Resource not found
const is404Response = (response) => {
  return response.status === 404;
};

//bad request
const is400Response = (response) => {
  return response.status === 400;
};

const is521Response = (response) => {
  return response.status === 521;
};

const setCorrelationId = (correlationId) => {
  sessionStorage.setItem("reqCorrelationId", correlationId);
};

const fetchWithLang = (url, params, lang) => {
  const urlObject = new URL(url);
  urlObject.searchParams.set("lang", lang ? lang : i18n.language);
  return fetch(urlObject.toString(), params);
};

const makeParams = (method = "GET", data = null, token = null) => {
  method = method.toUpperCase();
  let params = {
    cache: "no-cache",
    headers: {
      accept: "application/json",
    },
    method: method,
  };
  if (data) params.body = JSON.stringify(data);
  if (method === "POST" || method === "PUT")
    params.headers["content-type"] = "application/json";
  if (token) params.headers["Authorization"] = token;
  params.headers["correlation-id"] = uuid();
  setCorrelationId(params.headers["correlation-id"]);
  return params;
};

const makeFileParams = (method = "POST", file, token = null) => {
  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("correlation-id", uuid());

  const formData = new FormData();
  formData.append("file", file);
  formData.append("originalname", "image");

  const requestOptions = {
    method: method || "POST",
    headers: headers,
    body: formData,
  };
  return requestOptions;
};

const makeMediaSubmitParams = (method = "POST", token = null) => {
  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("correlation-id", uuid());

  const requestOptions = {
    method: method || "POST",
    headers: headers,
  };
  return requestOptions;
};

const makeMediaDeleteParams = (method = "DELETE", token = null) => {
  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("correlation-id", uuid());

  const requestOptions = {
    method: method || "DELETE",
    headers: headers,
  };
  return requestOptions;
};

const makeMediaDownloadParams = (method = "GET", token = null) => {
  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("correlation-id", uuid());

  const requestOptions = {
    method: method || "GET",
    headers: headers,
  };
  return requestOptions;
};

const makeMediaFileParams = (method = "POST", file, token = null) => {
  const headers = new Headers();
  headers.append("Authorization", token);
  headers.append("correlation-id", uuid());

  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: method || "POST",
    headers: headers,
    body: formData,
  };
  return requestOptions;
};

export const mediaUploadDeleteFile = (surveyId, questionId, blobName) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeMediaDeleteParams("DELETE", token);
      return fetch(
        blob_endpoint +
          `/ufms/${surveyId}/${questionId}/${blobName}/blob/delete`,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (response) return Promise.resolve(response);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const mediaUploadDeleteSurvey = (surveyId, questionId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeMediaDeleteParams("DELETE", token);
      return fetch(
        blob_endpoint + `/ufms/${surveyId}/${questionId}/blob/deleteSurvey`,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (response) return Promise.resolve(response);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const mediaUploadDownloadSurvey = async (surveyId, questionId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeMediaDownloadParams("GET", token);
      return fetch(
        blob_endpoint + `/ufms/${surveyId}/${questionId}/download`,
        params
      );
    })
    .then((res) => window.open(res.url, "_blank", "noopener,noreferrer"));
};

export const mediaUploadFile = (
  file,
  surveyId,
  questionId,
  responseId,
  userId
) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeMediaFileParams("POST", file, token);
      return fetch(
        blob_endpoint +
          `/ufms/${surveyId}/${questionId}/${responseId}/${userId}/blob/save`,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (response) return Promise.resolve(response);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const mediaUploadSubmitFile = (surveyId, questionId, blobName) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeMediaSubmitParams("POST", token);
      return fetch(
        blob_endpoint +
          `/ufms/${surveyId}/${questionId}/${blobName}/blob/submit`,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (response) return Promise.resolve(response);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};
const checkAndSendAPIErroTrace = async (response, requestParams) => {
  const clonedResponse = response.clone();
  try {
    if (is200Response(clonedResponse)) {
      clonedResponse.json().then((responseJson) => {
        if (isResponseFailed(responseJson)) {
          trackTrace(
            `${requestParams.method} :${clonedResponse.url} - Status:${clonedResponse.status}`,
            "API_ERROR_TESTING",
            requestParams.headers["correlation-id"]
          );
        }
      });
    } else {
      clonedResponse.text().then((text) => {
        trackTrace(
          `${requestParams.method} :${clonedResponse.url} - Status:${clonedResponse.status}`,
          "API_ERROR",
          JSON.stringify({
            "correlation-id": requestParams.headers["correlation-id"],
            response: text,
          })
        );
      });
    }
  } catch (error) {
    trackTrace(
      `EXCEPTION_CATCH`,
      "API_ERROR_TESTING",
      requestParams.headers["correlation-id"]
    );
  }
};

export const getSurvey = (surveyId, lang) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetch(
        endpoint + "/survey/" + surveyId + `?lang=${lang ? lang : ""}`,
        params
      );
    })

    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((responseJson) => {
          if (isResponseSuccess(responseJson)) {
            return formatSurveyServerToApp(responseJson.message.survey);
          }
        });
      } else if (is404Response(response)) {
        return { surveyExists: false, drupalId: surveyId };
      } else if (is403Response(response)) {
        return {
          surveyExists: true,
          surveyAuthorized: false,
          drupalId: surveyId,
        };
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    })
    .catch((err) => {
      window.console.error(err);
    });
};

export const getTemplateList = (lang) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetchWithLang(TEMPLATE_ENDPOINT, params, lang);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((responseJson) => {
          if (isResponseSuccess(responseJson)) {
            return responseJson;
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getTemplateDetails = (templateId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetchWithLang(TEMPLATE_ENDPOINT + templateId, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((responseJson) => {
          if (isResponseSuccess(responseJson)) {
            return {
              questions: formatSurveyQuestionsServerToApp(
                responseJson.message.questions
              ),
              replace_text_values: responseJson.message.replace_text_values,
            };
          }
        });
      } else if (is404Response(response)) {
        return { templateExist: false, templateId };
      } else if (is403Response(response)) {
        return {
          templateExist: true,
          templateAuthorized: false,
          templateId,
        };
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getQuestionBank = (data) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("post", data, token);

      return fetch(endpoint + "/templates/questions", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return response.message.map((quesBankItem) => ({
              ...quesBankItem,
              questions: formatSurveyQuestionsServerToApp(
                quesBankItem.questions
              ),
            }));
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const searchQuestionBank = async (searchQuery) => {
  let params;
  let token;
  try {
    token = await returnTokenOrForceReAuthentication();
  } catch (reason) {
    if (reason === "token-expired") {
      redirectUserToSSO();
      return Promise.reject("token-expired");
    }
  }
  params = makeParams("post", { questionContains: searchQuery }, token);

  const response = await fetch(endpoint + "/templates/questions", params);
  checkAndSendAPIErroTrace(response, params);
  if (is200Response(response)) {
    return response.json().then((response) => {
      return response.message.map((quesBankItem) => ({
        ...quesBankItem,
        questions: formatSurveyQuestionsServerToApp(quesBankItem.questions),
      }));
    });
  } else if (is401Response(response)) {
    redirectUserToSSO();
  } else if (is500Response(response)) {
    return Promise.resolve([]);
  }
};

export const publishSurvey = (
  id,
  surveyStatus,
  modified_on,
  completed = []
) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })

    .then((token) => {
      params = makeParams(
        "put",
        {
          action: "update-status",
          status: surveyStatus,
          modified_on: modified_on,
          completed: completed,
        },
        token
      );

      return fetch(SURVEY_ENDPOINT_SLASH_SUFFIXED + id, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((responseJson) => {
          if (isResponseSuccess(responseJson)) {
            return responseJson;
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is403Response(response)) {
        return Promise.reject(
          "403: You are not authorized to deactivate/publish this survey."
        );
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const debouncedSearchCIP = _.debounce(
  (searchQuery) => {
    let params;
    return returnTokenOrForceReAuthentication()
      .catch((reason) => {
        if (reason === "token-expired") {
          redirectUserToSSO();
          return Promise.reject("token-expired");
        }
      })

      .then((token) => {
        params = makeParams("post", { queryString: searchQuery }, token);

        return fetch(SEARCH_CIP, params);
      })
      .then((response) => {
        checkAndSendAPIErroTrace(response, params);
        if (is200Response(response)) {
          return response.json().then((responseJson) => {
            return Promise.resolve(responseJson.message);
          });
        } else if (is401Response(response)) {
          redirectUserToSSO();
        } else if (is500Response(response)) {
          return Promise.resolve([]);
        } else {
          return response.text().then((text) => Promise.reject(text));
        }
      });
  },
  300,
  {
    leading: true,
    trailing: false,
  }
);

export const createSurvey = (payload) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })

    .then((token) => {
      const thirdPartySettings = payload.thirdPartySettings;
      if (thirdPartySettings?.languages) {
        thirdPartySettings.languages = Object.keys(
          thirdPartySettings.languages
        );
      }
      params = makeParams(
        "post",
        {
          ...thirdPartySettings,
          title: payload.name,
          status: "closed",
          welcome_theme: THEME_DEFAULT,
          welcome_head: payload.name,
          thanks_theme: THEME_DEFAULT,
          thanks_head: THANK_YOU_DEFAULT,
          is_publishable: false,
          submissions_count: 0,
          views: 0,
          closingMessage: payload.closingMessage, // sending closing message of default lang
        },
        token
      );

      return fetchWithLang(SURVEY_ENDPOINT, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response);
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const cloneSurvey = (surveyId, surveyTitle) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })

    .then((token) => {
      params = makeParams(
        "post",
        {
          title: surveyTitle,
        },
        token
      );

      return fetch(CLONE_SURVEY_ENDPOINT_SLASH_SUFFIXED + surveyId, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response);
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    })
    .then((response) => getSurvey(response.message));
};

export const saveSurvey = (
  surveyId,
  data,
  multiLingualQuestions,
  multiLingualGreetings,
  multiLingualClosingMessage,
  modifiedMultiLingualQuestions,
  lang,
  languages
) => {
  let params;
  if (data && (data.role === ROLE.OWNER || data.role === ROLE.CO_OWNER)) {
    return returnTokenOrForceReAuthentication()
      .catch((reason) => {
        if (reason === "token-expired") {
          redirectUserToSSO();
          return Promise.reject("token-expired");
        }
      })
      .then((token) => {
        params = makeParams(
          "put",
          {
            translations: formatTranslations(
              data,
              multiLingualQuestions,
              multiLingualClosingMessage,
              multiLingualGreetings,
              lang
            ),
            modified_on: data.modified,
            dataSources: data.thirdPartySettings.dataSources,
            modifiedQuestions: formatModifiedQuestions(
              data,
              modifiedMultiLingualQuestions,
              lang
            ),
            lang: lang,
            languages: languages,
            createdFromTemplate: data.thirdPartySettings.createdFromTemplate,
            templateId: data.thirdPartySettings.templateId,
          },
          token
        );

        return fetch(SURVEY_ENDPOINT_SLASH_SUFFIXED + surveyId + SAVE, params);
      })
      .then((response) => {
        checkAndSendAPIErroTrace(response, params);
        if (is200Response(response)) {
          return response.json().then((response) => {
            if (isResponseSuccess(response)) {
              return formatSurveyServerToApp(response);
            }
          });
        } else if (is401Response(response)) {
          //  return Promise.reject("401: Authentication expired. Kindly relogin.");
          redirectUserToSSO();
        } else if (is403Response(response)) {
          return Promise.reject(
            "403: You are not authorized to edit this survey."
          );
        } else if (is500Response(response)) {
          return Promise.reject("500: Internal Server error.");
        } else {
          return response.text().then((text) => Promise.reject(text));
        }
      });
  } else {
    return Promise.reject("403: You are not authorized to edit this survey.");
  }
};

export const updateSurvey = (surveyId, data, updateHistory, lang) => {
  let params;
  //convert to set and back to array to remove duplicates
  updateHistory = Array.from(new Set(updateHistory));
  if (data && (data.role === ROLE.OWNER || data.role === ROLE.CO_OWNER)) {
    return returnTokenOrForceReAuthentication()
      .catch((reason) => {
        if (reason === "token-expired") {
          redirectUserToSSO();
          return Promise.reject("token-expired");
        }
      })

      .then((token) => {
        params = makeParams(
          "put",
          {
            action: "update-questions",
            questions: formatSurveyAppToServer(data, lang),
            modifiedQuestions: data.modifiedQuesList,
            closingMessage: data.thirdPartySettings.closingMessage,
            greetings: data.thirdPartySettings.greetings,
            dataSources: data.thirdPartySettings.dataSources,
            updateHistory: updateHistory,
            modified_on: data.modified,
            createdFromTemplate: data.thirdPartySettings.createdFromTemplate,
            templateId: data.thirdPartySettings.templateId,
          },
          token
        );

        return fetch(
          SURVEY_ENDPOINT_SLASH_SUFFIXED +
            surveyId +
            `?lang=${lang ? lang : i18next.language}`,
          params
        );
      })
      .then((response) => {
        checkAndSendAPIErroTrace(response, params);
        if (is200Response(response)) {
          return response.json().then((response) => {
            if (isResponseSuccess(response)) {
              return formatSurveyServerToApp(response);
            }
          });
        } else if (is401Response(response)) {
          //  return Promise.reject("401: Authentication expired. Kindly relogin.");
          redirectUserToSSO();
        } else if (is403Response(response)) {
          return Promise.reject(
            "403: You are not authorized to edit this survey."
          );
        } else if (is500Response(response)) {
          return Promise.reject("500: Internal Server error.");
        } else {
          return response.text().then((text) => Promise.reject(text));
        }
      });
  } else {
    return Promise.reject("403: You are not authorized to edit this survey.");
  }
};

export const updateSurveyTitle = (
  surveyId,
  surveyTitle,
  updateHistory,
  modified_on
) => {
  let params;
  //convert to set and back to array to remove duplicates
  updateHistory = Array.from(new Set(updateHistory));
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams(
        "put",
        {
          title: surveyTitle,
          action: "update-title",
          updateHistory: updateHistory,
          modified_on: modified_on,
        },
        token
      );

      return fetch(SURVEY_ENDPOINT_SLASH_SUFFIXED + surveyId, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response);
          }
        });
      } else if (is401Response(response)) {
        //  return Promise.reject("401: Authentication expired. Kindly relogin.");
        redirectUserToSSO();
      } else if (is403Response(response)) {
        return Promise.reject(
          "403: You are not authorized to edit this survey."
        );
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

//TODO: Handle negative flow
export const deleteSurvey = (surveyId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })

    .then((token) => {
      params = makeParams("delete", null, token);

      return fetch(SURVEY_ENDPOINT_SLASH_SUFFIXED + surveyId, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response);
          }
        });
      } else if (is401Response(response)) {
        //  return Promise.reject("401: Authentication expired. Kindly relogin.");
        redirectUserToSSO();
      } else if (is403Response(response)) {
        return Promise.reject(
          "403: You are not authorized to delete this survey."
        );
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const logout = () => {
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => makeParams("post", null, token))
    .then((params) => fetch(LOGOUT_ENDPOINT, params))
    .then((response) => response.json());
};

export const getSurveys = (data) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("post", data, token);

      return fetch(endpoint + "/survey/list", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response);
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getPolicies = () => {
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then(() => {
      const msg = POLICY_LIST.sort((a, b) =>
        a.policy_name > b.policy_name ? 1 : -1
      );
      let policies = [];
      for (let index in msg) {
        policies.push({
          label: msg[index].policy_name,
          value: msg[index].policy_id,
          link: msg[index].policy_link,
        });
      }
      return Promise.resolve(policies);
    });
};

//TODO: Remove Later if not needed
export const getPolicyByName = (name) => {
  return fetch(
    endpoint + "/api/privacy-policy?title=" + name,
    makeParams("get")
  )
    .then((response) => response.json())
    .then((response) => {
      let policy = response && response[0];
      if (policy) {
        return {
          label: policy.title,
          value: policy.nid,
          link: policy.field_policy_link,
        };
      }
    });
};

export const getThemeImageList = (themeName) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetch(endpoint + "/theme-images/" + themeName, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          let themeImageList = [];
          if (
            response.type === "success" &&
            response.message &&
            response.message.length > 0
          ) {
            themeImageList = response.message;
          }
          return Promise.resolve(themeImageList);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404: Could not get theme image list.");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

//TODO: Handle negative flow
export const createSubmission = (
  surveyId,
  data,
  endTime,
  anonymousAccess,
  modified_on,
  lang,
  campaignID,
  customerID,
  waveID
) => {
  window.console.log("pranav", "huh");
  //data.spent_time will be present if survey is stored in localStorage during SSO redirection
  let params;
  let spentTime = data.spent_time;
  let data2 = {
    survey_id: surveyId,
    spent_time: spentTime ? spentTime : Math.round(endTime - data.startTime),
    answers: {},
    modified_on: modified_on,
  };
  if (campaignID && customerID) {
    data2 = {
      ...data2,
      customer_id: customerID,
      campaign_id: campaignID,
      campaign_enabled: "true",
    };
    if (waveID) {
      //wave is optional for campaign
      data2 = { ...data2, wave_id: waveID };
    }
  }
  _.each(_.omitBy(data.answers, _.isNull), (v, id) => {
    if (v?.questionType === "contact_form") {
      const contactAnswers = v.answers.map(
        ({ fieldId, fieldType, label, value, nameFieldType }) => {
          const answer = {
            "#label": label,
            "#fieldType": fieldType,
            "#fieldId": fieldId,
          };

          if (fieldType === "name" && nameFieldType != 1) {
            answer["#value"] = Object.entries(value)
              .map(([, v]) => v)
              .join(" ");
          } else {
            answer["#value"] = value;
          }

          answer["#value"] = answer["#value"] || "";
          return answer;
        }
      );
      data2.answers[id] = contactAnswers;
    } else if (v?.questionType === "mediaUpload") {
      data2.answers[id] = v.answers
        .map((fileData) => fileData.fileUploadName)
        .join(", ");
    } else {
      if (Array.isArray(v)) {
        v.forEach((item) => {
          if (
            item.questionType === "matrix" &&
            Object.prototype.hasOwnProperty.call(item, "index")
          ) {
            delete item["index"];
            delete item["questionType"];
          }
        });
      }
      data2.answers[id] = v;
    }
  });
  data2.piping_answers = data.piping_answers;
  data2.status = data.status;
  if (anonymousAccess == false) {
    return returnTokenOrForceReAuthentication()
      .catch((reason) => {
        if (reason === "token-expired") {
          //store survey respose in localStorage and redirect to SSO
          const EXPIRY_HRS = 4;
          let expiryTime = new Date();
          expiryTime.setTime(
            expiryTime.getTime() + EXPIRY_HRS * 60 * 60 * 1000
          );
          data2.surveyId = surveyId;
          data2.anonymousAccess = anonymousAccess;
          data2.expiryTime = expiryTime;
          localStorage.setItem(
            CACHE_SURVEY_RESPONSE_KEY,
            JSON.stringify(data2)
          );
          redirectUserToSSO();
          return Promise.reject({ code: reason, errorMessage: reason });
        }
      })
      .then((token) => {
        params = makeParams("post", data2, token);

        return fetch(
          endpoint + `/survey/submit?_format=json&lang=${lang}`,
          params
        );
      })
      .then((response) => {
        localStorage.removeItem(CACHE_SURVEY_RESPONSE_KEY);
        return handleSubmissionResponse(response, params);
      });
  } else {
    params = makeParams("post", data2);

    return fetch(
      endpoint + `/survey/submit?_format=json&lang=${lang}`,
      params
    ).then((response) => handleSubmissionResponse(response, params));
  }
};

function handleSubmissionResponse(response, params) {
  checkAndSendAPIErroTrace(response, params);
  if (is200Response(response)) {
    return response.json().then((response) => {
      if (isResponseSuccess(response)) {
        return Promise.resolve(response);
      }
    });
  } else if (
    is500Response(response) ||
    is520Response(response) ||
    is521Response(response)
  ) {
    return Promise.reject("Internal error");
  } else if (is400Response(response)) {
    return response.text().then((text) => {
      try {
        const res = JSON.parse(text);
        if (res.status == false && res?.data?.code === "INVALID_PARAMS") {
          return Promise.reject(JSON.stringify({ message: "INVALID_PARAMS" }));
        }
        return Promise.reject(JSON.stringify({ message: "Internal error" }));
      } catch (err) {
        return Promise.reject(JSON.stringify({ message: "Internal error" }));
      }
    });
  } else {
    return response.text().then((text) => Promise.reject(text));
  }
}

export const updateThirdPartySettings = (
  id,
  data,
  updateHistory,
  lang = i18next.language
) => {
  let params;
  //convert to set and back to array to remove duplicates
  data.updateHistory = Array.from(new Set(updateHistory));
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      if (data.languages) {
        data.languages = Object.keys(data.languages);
      }

      params = makeParams("put", data, token);

      return fetch(
        SURVEY_ENDPOINT_SLASH_SUFFIXED + id + `?lang=${lang}`,
        params
      );
    })

    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response);
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is403Response(response)) {
        return Promise.reject(
          "403: You are not authorized to update settings for this survey."
        );
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else if (is422Response(response)) {
        return Promise.reject("422: Update violation.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getSurveySubmissions = (surveyId, questionId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetch(
        SURVEY_ENDPOINT_SLASH_SUFFIXED +
          surveyId +
          "/submissions/" +
          (questionId ? questionId : "summary"),
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response.message);
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is404Response(response)) {
        return Promise.reject("404: No such survey exists.");
      } else if (is500Response(response)) {
        return Promise.reject("internal server error");
      } else if (is521Response(response)) {
        // retry to get survey submissions in case of 521 error
        return getSurveySubmissions(surveyId, questionId);
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getSurveyForTakerView = (
  surveyId,
  preview,
  lang,
  campaignDetails,
  skipView = false
) => {
  let params;
  params = makeParams("get", null, cookie.load("accessToken"));

  return fetch(
    endpoint +
      "/surveytaker/" +
      surveyId +
      `?lang=${lang}` +
      (preview === true ? "&previewing=true" : "") +
      (skipView === true ? "&skipView=true" : "") +
      (campaignDetails?.campaignID?.length > 0
        ? `&campaign_enabled=true&campaign_id=${campaignDetails.campaignID}`
        : "") +
      (campaignDetails?.customerID?.length > 0
        ? `&customer_id=${campaignDetails.customerID}`
        : "") +
      (campaignDetails?.waveID?.length > 0
        ? `&wave_id=${campaignDetails.waveID}`
        : ""),
    params
  )
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is401Response(response)) {
        redirectUserToSSO();
        response = {
          drupalId: surveyId,
          requireSSO: true,
        };
        return Promise.reject(response);
      }
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return formatSurveyServerToApp(response.message.survey);
          }
        });
      } else if (is400Response(response)) {
        return response.text().then((text) => {
          let error = Object.assign({}, JSON.parse(text));
          if (error.message === "SURVEY_CLOSED") {
            Object.assign(error, {
              drupalId: surveyId,
              surveyExists: false,
            });
          }
          return Promise.reject(error);
        });
      } else if (is404Response(response)) {
        return response.text().then((text) => {
          let error = Object.assign({}, JSON.parse(text));

          Object.assign(error, {
            drupalId: surveyId,
            surveyExists: false,
          });

          return Promise.reject(error);
        });
      } else if (is403Response(response)) {
        let error = { previewUnAuthorized: true };
        return Promise.reject(error);
      } else {
        response.text().then((text) => {
          return Promise.reject(JSON.parse(text));
        });
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const download = (id, name) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetch(SURVEY_ENDPOINT_SLASH_SUFFIXED + id + "/download", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        if (
          response.headers.get("content-type") === "text/csv; charset=utf-8"
        ) {
          return response.blob().then((csvData) => {
            let csvBlob = new Blob(["\ufeff", csvData]);
            var url = window.URL.createObjectURL(csvBlob);
            var a = document.createElement("a");
            a.href = url;
            name = name ? name.split(" ").join("_") : "Unnamed_Survey";
            a.download = name + ".csv";
            document.body.appendChild(a);
            a.click();
            a.remove();
            return true;
          });
        } else {
          return response.json().then((responseJson) => {
            if (isResponseSuccess(responseJson)) {
              return responseJson.message;
            }
          });
        }
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getReleaseNotes = () => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetch(endpoint + "/release-notes", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => response.message);
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const submitIdea = (ideaData) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("post", { mode: "idea", ...ideaData }, token);

      return fetch(endpoint + "/uservoice", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          //If not success submission
          if (!isResponseSuccess(response)) {
            return {
              saved: false,
            };
          } else {
            return {
              saved: true,
            };
          }
        });
      }
    });
};

export const submitNps = (npsData) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("post", { mode: "nps", ...npsData }, token);

      return fetch(endpoint + "/uservoice", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          //If not success submission
          if (!isResponseSuccess(response)) {
            return {
              saved: false,
            };
          } else {
            return {
              saved: true,
            };
          }
        });
      }
    });
};

export const uploadSourceFile = (
  surveyId,
  fileIdentifier,
  file,
  workSheets
) => {
  let params;
  const abortController = new AbortController();

  return {
    cancel: () => abortController.abort(),
    response: returnTokenOrForceReAuthentication()
      .catch((reason) => {
        if (reason === "token-expired") {
          redirectUserToSSO();
          return Promise.reject("token-expired");
        }
      })
      .then((token) => {
        const headers = new Headers();
        headers.append("Authorization", token);
        headers.append("correlation-id", uuid());
        headers.append("Content-Length", file.size);

        const formData = new FormData();
        formData.append("file", file);
        workSheets && formData.append("workSheets", JSON.stringify(workSheets));
        params = {
          method: fileIdentifier ? "PUT" : "POST",
          headers: headers,
          body: formData,
          signal: abortController.signal,
        };
        if (fileIdentifier) {
          return fetch(
            endpoint + `/source-file/${surveyId}/${fileIdentifier}`,
            params
          );
        } else {
          return fetch(endpoint + `/source-file/${surveyId}`, params);
        }
      })
      .then((response) => {
        checkAndSendAPIErroTrace(response, params);
        if (is200Response(response)) {
          return response.json().then((response) => {
            if (response?.message?.sourceFiles)
              return Promise.resolve(response.message.sourceFiles);
          });
        } else if (is404Response(response)) {
          return Promise.reject("404:Survey not found");
        } else if (is401Response(response)) {
          redirectUserToSSO();
        } else if (is500Response(response)) {
          return Promise.reject("500: Internal Server error.");
        } else {
          return response.json().then((json) => Promise.reject(json));
        }
      }),
  };
};

export const deleteSourceFile = (surveyId, file) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("DELETE", null, token);
      return fetch(endpoint + `/source-file/${surveyId}/${file}`, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (response?.message?.sourceFiles)
            return Promise.resolve(response.message.sourceFiles);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey or file not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const previewSourceFile = (surveyId, file, lang, limit) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("GET", null, token);
      return fetch(
        endpoint +
          `/source-file/${surveyId}/${file}?lang=${lang}&limit=${limit}`,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (response?.message?.[file])
            return Promise.resolve(response.message[file]);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey or file not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getSourceFileContent = (surveyId, file) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("POST", { fileIds: [file] }, token);
      return fetch(endpoint + `/source-file/${surveyId}/file-urls`, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response
          .json()
          .then((response) => {
            if (response?.message) {
              return fetch(contentBaseUrl + response.message[file]);
            }
          })
          .then((fileData) => {
            return Promise.resolve(fileData.body);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey or file not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const uploadFile = (file, surveyId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeFileParams("POST", file, token);
      return fetch(endpoint + `/content/${surveyId}/blob`, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (response && response.message && response.message.contentUrl)
            return Promise.resolve(response.message.contentUrl);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const deleteFile = (fileName, surveyId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("DELETE", null, token);

      return fetch(
        endpoint + `/survey/${surveyId}/deleteContent/${fileName}`,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (response && response.message && response.message)
            return Promise.resolve(response.message);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey or file not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getContentFile = async (contentUrl, surveyId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetch(
        `${endpoint}/content/${surveyId}/blob/${contentUrl}`,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.blob().then((buffer) => {
          const contentStr = URL.createObjectURL(buffer);
          return contentStr;
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getContentUrlsFromBlobIds = (blobIds, surveyId) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("post", { blob_ids: blobIds }, token);

      return fetch(endpoint + `/content/${surveyId}/blob-urls`, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (
            response &&
            response.message &&
            response.message &&
            response.message.paths
          )
            return Promise.resolve(response.message.paths);
        });
      } else if (is404Response(response)) {
        return Promise.reject("404:Survey or file not found");
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getContentUrlsFromBlobIdsSurveyTaker = (blobIds, surveyId) => {
  let params;
  const handleAuthCheck = (response) => {
    checkAndSendAPIErroTrace(response, params);
    if (is401Response(response)) {
      redirectUserToSSO();
      response = {
        drupalId: surveyId,
        requireSSO: true,
      };
    }
    if (is200Response(response)) {
      return response.json().then((response) => {
        if (
          response &&
          response.message &&
          response.message &&
          response.message.paths
        )
          return Promise.resolve(response.message.paths);
      });
    } else if (is404Response(response)) {
      return Promise.reject("404:Survey or file not found");
    } else if (is500Response(response)) {
      return Promise.reject("500: Internal Server error.");
    }
    return Promise.reject(response);
  };
  params = makeParams(
    "post",
    { blob_ids: blobIds },
    cookie.load("accessToken")
  );

  return fetch(endpoint + `/content/${surveyId}/taker/blob-urls`, params).then(
    (response) => handleAuthCheck(response)
  );
};

export const getUserInfo = () => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetch(endpoint + "/user-info", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => response.message);
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const postUserInfo = (data) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("post", data, token);

      return fetch(endpoint + "/user-info", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response);
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is403Response(response)) {
        return Promise.reject(
          "403: You are not authorized to update data for this user."
        );
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const updateUserInfo = (data) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("put", data, token);

      return fetch(endpoint + "/user-info", params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => {
          if (isResponseSuccess(response)) {
            return Promise.resolve(response);
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is403Response(response)) {
        return Promise.reject(
          "403: You are not authorized to update data for this user."
        );
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const getQuestionOptions = (lang = "") => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);

      return fetch(
        endpoint + "/templates/question-options?lang=" + lang,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => response.message);
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const setSurveyUserNotification = async (
  id,
  modified_on,
  enabled,
  recipients,
  updateHistory
) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })

    .then((token) => {
      params = makeParams(
        "put",
        {
          modified_on: modified_on,
          enabled: enabled,
          recipients: recipients,
          updateHistory: updateHistory,
        },
        token
      );

      return fetch(SURVEY_ENDPOINT_SLASH_SUFFIXED + id + NOTIFICATION, params);
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((responseJson) => {
          if (isResponseSuccess(responseJson)) {
            return responseJson;
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is403Response(response)) {
        return Promise.reject(
          "403: You are not authorized to enable notification to this survey."
        );
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    });
};

export const autoSaveResponse = async (id, data, campaignDetails) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })

    .then((token) => {
      params = makeParams("put", data, token);
      const campaignParams = getCampaignParams(campaignDetails);
      return fetch(
        SURVEY_ENDPOINT_SLASH_SUFFIXED + id + AUTO_SAVE + campaignParams,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((responseJson) => {
          if (isResponseSuccess(responseJson)) {
            return responseJson;
          }
        });
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    })
    .catch((err) => console.error(err));
};

export const getAutoSaveResponse = async (surveyId, campaignDetails) => {
  let params;
  return returnTokenOrForceReAuthentication()
    .catch((reason) => {
      if (reason === "token-expired") {
        redirectUserToSSO();
        return Promise.reject("token-expired");
      }
    })
    .then((token) => {
      params = makeParams("get", null, token);
      const campaignParams = getCampaignParams(campaignDetails);
      return fetch(
        SURVEY_ENDPOINT_SLASH_SUFFIXED + surveyId + AUTO_SAVE + campaignParams,
        params
      );
    })
    .then((response) => {
      checkAndSendAPIErroTrace(response, params);
      if (is200Response(response)) {
        return response.json().then((response) => response);
      } else if (is401Response(response)) {
        redirectUserToSSO();
      } else if (is500Response(response)) {
        return Promise.reject("500: Internal Server error.");
      } else {
        return response.text().then((text) => Promise.reject(text));
      }
    })
    .catch((err) => console.error(err));
};
